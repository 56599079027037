@import 'homepage';

.header{
    .invisible{
        padding-left: 24px;
        padding-right: 24px;
    }
}

.footer{
    position: fixed;
    width: 100%;
    padding-top: 10px;
    bottom: 0;
    height: 90px;
}

.MuiDrawer{
    font-size: 16px;
}

#root > .site{
    padding-bottom: 90px;
}

.fotoZoom{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 90px;
    display: none;
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(5px);
}

.foto{
    position: flex;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 100px;
}

.fotoZoomShow{
    display: flex;
}

.closeIcon{
    z-index: 2;
    position: absolute;
    cursor: pointer; 
    right: 20px;
    top: 15px;
}

.linkTo{
    all: unset;
}