.homepage{
    .title{
        padding: auto;
    }
    .subtitle{
        padding: auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .homepage{
        .title{
            padding: 20px;
        }
        .subtitle{
            padding: auto;
        }
    }   
}